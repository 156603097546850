<template>
  <div class="mx-auto max-w-screen-3xl">
    <Swiper
      :modules="modules"
      :slides-per-view="1"
      :spaceBetween="16"
      :speed="600"
      :parallax="true"
      class="relative m-0 mx-auto h-auto w-full p-0"
    >
      <SwiperSlide v-for="(item, idx) in data.slides" :key="idx">
        <div class="grid grid-cols-12 gap-4 lg:gap-8">
          <NuxtLink
            class="relative col-span-12 lg:order-1 lg:col-start-5 lg:col-end-13"
            :to="item.link ? localePathByType('page', item.link.slug) : item.externalLink"
            :target="item.externalLink ? '_blank' : undefined"
          >
            <p
              class="pointer-events-none absolute left-0 top-16 z-2 mb-24 px-4 text-4xl text-white lg:left-24 lg:top-20 lg:text-6xl"
            >
              {{ item.title }}
            </p>

            <Image
              class="aspect-h-3 aspect-w-2 h-full lg:aspect-h-10 lg:aspect-w-16"
              imgClass="object-cover hover:scale-110 duration-300"
              :src="item.secondaryImage.responsiveImage.srcSet"
              :src-webp="item.secondaryImage.responsiveImage.webpSrcSet"
              :width="item.secondaryImage.responsiveImage.width"
              :height="item.secondaryImage.responsiveImage.height"
              :alt="item.secondaryImage?.responsiveImage!.alt"
              :sizes="item.secondaryImage?.responsiveImage!.sizes"
            />
            <div
              data-swiper-parallax="-300"
              class="quotes mb-6 text-lg lg:mb-12 lg:text-2xl"
              v-html="item.html"
            />
          </NuxtLink>

          <div
            class="col-span-12 lg:col-span-3 lg:flex lg:flex-col lg:justify-end"
            data-swiper-parallax="-350"
          >
            <p
              class="mb-3 pl-4 text-sm uppercase tracking-widest text-neutral-900 lg:pl-16"
              v-text="item.subtitle"
            />

            <div
              class="mb-6 pl-4 text-sm font-extrabold tracking-widest lg:pl-16"
              v-html="item.body"
            />

            <Image
              class="aspect-h-8 aspect-w-9"
              :src="item.image.responsiveImage.srcSet"
              :src-webp="item.image.responsiveImage.webpSrcSet"
              :width="item.image.responsiveImage.width"
              :height="item.image.responsiveImage.height"
              :alt="item.image?.responsiveImage!.alt"
              :sizes="item.image?.responsiveImage!.sizes"
            />
          </div>
        </div>
      </SwiperSlide>
      <SliderNav class="absolute bottom-0 right-0 z-2" />
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import { Parallax, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css'

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const modules = [Parallax, A11y]
</script>
