<template>
  <div>
    <button
      class="h-28 bg-primary pl-6 pr-4 transition-all duration-300 hover:bg-red-70 disabled:bg-neutral-400 lg:h-40 lg:pl-10"
      :disabled="swiper.isBeginning"
      :title="$t('swiper.previous')"
      :icon="{ name: 'arrow-left', color: 'white', size: 'lg' }"
      @click.stop.prevent="swiper.slidePrev()"
    >
      <Icon name="arrow-left" color="white" size="lg" />
    </button>

    <button
      class="h-28 bg-primary pl-4 pr-6 transition-all duration-300 hover:bg-red-70 disabled:bg-neutral-400 lg:h-40 lg:pr-10"
      :disabled="swiper.isEnd"
      :title="$t('swiper.next')"
      :icon="{ name: 'arrow-left', color: 'white', size: 'lg' }"
      @click.stop.prevent="swiper.slideNext()"
    >
      <Icon name="arrow-right" color="white" size="lg" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue'

const swiper = useSwiper()
</script>
